.tabsList {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tabItem {
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;

  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* color: #58667E; */
  color: #898989;
}
.tabActive {
  color: #FFFFFF;
  background-color: #33B1FF;
  border-radius: 5px;
  font-weight: 600;
}
