.rowContainer {
  background: #FFFFFF;
  border-radius: 0.625rem;
  display: grid;
  padding: 0.3rem 0.7rem 0.6rem 0.7rem;
  align-items: center;
  /* margin: 0.5rem 0; */
}

.TraderList {
  background: #FFFFFF;
  /* border-radius: 0.625rem; */
  align-items: center;
  margin: 0.68rem 0;
}

.darkTraderList {
  background: black;
  align-items: center;
  margin: 0.68rem 0;
  border: 1px solid #44403c;
  border-radius: 4px;
  .tableCell {
    color: #beb9b9;
  }
  
}

.expandList {
  position: absolute;
  top: 41%;
  right: 30px;
  font-size: 1.5rem !important;
  color: #c4c4c4 !important;
  cursor: pointer;
}
.shadowStyle:hover{
  box-shadow: 0px 0px 10px #d8d8d8;
}
.headerRow {
  background: none;
}
.dataRow {
  transition: transform;
}
.grid-6-cols {
  grid-template-columns: 1.5fr repeat(4, 1fr);

}

.grid6cols {
  /*grid-template-columns: 1.5fr repeat(4, 1fr) 4rem;*/
  grid-template-columns: 14% 13.5% 13.5% 13.5% 13.5% 15.8% 18%;
}


.roundIconBorder:before {
  width: 2.663rem;
  height: 2.663rem;
  content: "";
  position: absolute;
  top: 3px;
  left: 3.2px;
  border-radius: 50%;
}


.tableCell {
  font-size: 1.125rem;
  line-height: 133%;
  color: #3A3A3A;
}

.traderRowGrid {
  grid-template-columns: 14% 13.5% 13.5% 13.5% 13.5% 15.8% 18%;
  /* 14% 12.5% 14.7% 13.2% 14.3% 15.5% 19.4% */
}

.TraderHeaderSubGrid {
  grid-template-columns: 91.6% 3%;
  padding-top: 0;
}


.TraderHeaderSubGrid>span {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #898989;
}

.platformButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 10px;
  width: 146px;
  height: 27px;
  background: #33B1FF;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  order: 1;
  color: #FFFFFF
}

.fundButton {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 10px;
  width: 196px;
  height: 34px;
  background: #E7F7E5;
  border-radius: 7px;
  flex: none;
  order: 1;
  font-weight: 500;
  font-size: 14px;
  color: #39B118;

}


