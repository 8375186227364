.companyDataLayout {
    display: grid;
    /*grid-template-columns: 13.25rem 13.25rem 13.25rem 13.25rem 13.25rem;*/
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    /* grid-template-rows: 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem; */
    grid-template-rows: 6.25rem 6.25rem 6.25rem 6.25rem 6.25rem 6.25rem;
    grid-template-areas: "pnl-card pnl-card win-rate win-rate val-realized-profit"
        "pnl-card pnl-card win-rate win-rate val-running-pnl"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-fund"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-invest"
        "hot-cold-wallet hot-cold-wallet staking-table staking-table val-open-post"
        "hot-cold-wallet hot-cold-wallet staking-table staking-table val-total-curr-val";
    grid-gap: 0.5rem;
}

.balanceSheetDataLayout {
    display: grid;
    /*grid-template-columns: 13.25rem 13.25rem 13.25rem 13.25rem 13.25rem;*/
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem;
    grid-template-areas: "pnl-card pnl-card win-rate win-rate val-total-assets"
        "pnl-card pnl-card win-rate win-rate val-total-libilities"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-fund"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-curr-val"
        "hot-cold-wallet hot-cold-wallet profits-card profits-card book-value-hot"
        "hot-cold-wallet hot-cold-wallet profits-card profits-card book-value-cold";
    grid-gap: 1rem;
}
.bonusPoolDataLayout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem 7.375rem;
    grid-template-areas: "pnl-card pnl-card win-rate win-rate val-total-assets"
        "pnl-card pnl-card win-rate win-rate val-total-libilities"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-fund"
        "pnl-card2 pnl-card2 roi-card roi-card val-total-curr-val"
        "hot-cold-wallet hot-cold-wallet stats-card profits-card profits-card"
        "hot-cold-wallet hot-cold-wallet stats-card profits-card profits-card";
    grid-gap: 1rem;
}
.card {
    @apply bg-white;
}

.pnlCard {
    @apply bg-white;
    grid-area: pnl-card;
}

.winRate {
    @apply bg-white;
    grid-area: win-rate;
}
.stakingTable {
    @apply bg-white;
    grid-area: staking-table;
}

.valOpenPost {
    @apply bg-white;
    color: #026DB0;
    grid-area: val-open-post;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;
    white-space: nowrap;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}

.valTotalInvest {
    @apply bg-white;
    color: #026DB0;
    grid-area: val-total-invest;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}

.bookValueHot {
    @apply bg-white;
    color: #026DB0;
    grid-area: book-value-hot;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}

.bookValueCold {
    @apply bg-white;
    color: #026DB0;
    grid-area: book-value-cold;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}

.valTotalPost {
    @apply bg-white;
    grid-area: val-total-post;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}

.valTotalFund {
    @apply bg-white;
    color: #026DB0;
    grid-area: val-total-fund;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }

        /* .valueText {
            color: #fff;
        } */
    }
}

.valTotalCurrVal {
    @apply bg-white;
    color: #026DB0;
    grid-area: val-total-curr-val;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }
    }
}


.valRealizedProfit {
    @apply bg-white;
    grid-area: val-realized-profit;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }

        /* .valueText {
            color: #fff;
        } */
    }
}

.valRunningPnL {
    @apply bg-white;
    grid-area: val-running-pnl;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }

        /* .valueText {
            color: #fff;
        } */
    }
}

.valTotalLiabilities {
    @apply bg-white;
    grid-area: val-total-libilities;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }

        .valueText {
            color: #fff;
        }
    }
}

.valTotalAssets {
    @apply bg-white;
    grid-area: val-total-assets;
    @apply hover:bg-[#003A6D];
    @apply hover:text-white;

    &:hover {
        .Text {
            color: #fff;
        }

        .valueText {
            color: #fff;
        }
    }
}

.pnlCard2 {
    @apply bg-white;
    grid-area: pnl-card2;
}

.roiCard {
    @apply bg-white;
    grid-area: roi-card;
}

.hotColdWallet {
    @apply bg-white;
    grid-area: hot-cold-wallet
}

.statsCard {
    @apply bg-white;
    grid-area: stats-card;
}

.profitsCard {
    @apply bg-white;
    grid-area: profits-card;
}

.valueText {
    /* color: #026DB0; */
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Text {
    color: #58667E;
    text-align: center;
    font-family: "SF Pro Display";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.HeadingText {
    /* color: #3A3A3A; */
    font-family: "SF Pro Display";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    white-space: nowrap;
}

.paddingLeft19 {
    padding-left: 1.19rem;
}

.tooltipText {
    color: #FFF;
    fill: #fff;
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.01375rem
}

.HotColdListingHeader {
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;

}

.HotColdHeadingGrid {
    grid-template-columns: 25% 22.4% 28.5% 25.6%;
}

.InvestmentHeadingGrid {
    grid-template-columns: 34% 24.4% 23.5% 18.6%;
}

.HotColdrowGrid {
    grid-template-columns: 25% 22.4% 28.5% 25.6%;
    width: 100%;
    align-items: center;
}

.InvestmentRowGrid {
    grid-template-columns: 34% 24.4% 23.5% 18.6%;
    width: 100%;
    align-items: center;
}

.HotColdHeading {
    color: #898989;
    font-family: "SF Pro Display";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
}

.rowContainer {
    display: grid;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 3rem;
    border-top: 0.2px solid #f3f3f3;
}

.rowContainerMain .rowContainer:first-child {
    border-top: none;
}

.rowText {
    color: #3A3A3A;
    font-family: "SF Pro Display";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
}

.changeInPercentage {
    text-align: center;
    /* font-family: "DM Sans"; */
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: -0.015rem;
    align-items: end;
    display: flex;
}

.DistributionTitle {
    color: #3A3A3A;
    font-family: "SF Pro Display";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}

.detailsButtonContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    justify-content: center;
    margin-right: 42px;
    margin-left: auto;
    border-radius: 0.4375rem;
}

.detailsButton {
    background-color: #33B1FF;
    border-radius: 0.4375rem;
    color: #FFF;
    /* font-family: Inter; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
}

.LegendText {
    color: #3A3A3A;
    font-family: 'SF Pro Display';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem
}

.dark {
    .winRate,
    .bookValueCold,
    .bookValueHot,
    .hotColdWallet,
    .pnlCard,
    .pnlCard2,
    .profitsCard,
    .roiCard,
    .statsCard,
    .valOpenPost,
    .valRealizedProfit,
    .valRunningPnL,
    .valTotalAssets,
    .valTotalCurrVal,
    .valTotalFund,
    .valTotalInvest,
    .valTotalLiabilities,
    .valTotalPost,
    .winRate,
    .stakingTable {
        @apply bg-black text-[#beb9b9] hover:bg-[#303131]
    }

    .Text, .HeadingText, .DistributionTitle, .rowText, .LegendText {
        color: #beb9b9;
    }

    .HotColdListingHeader {
        @apply bg-black text-[#beb9b9]
    }

    .hotColdWallet:hover, .stakingTable:hover {
        .HotColdListingHeader {
            @apply bg-[#303131]
        }
    }
    .rowContainer{
        border-top: 0.2px solid #585151;
    }
}